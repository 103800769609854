import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import useAuthUser from 'hooks/common/useAuthUser';
import { pageAndLimitDefaultValue } from 'common/constants/common';
import projectApiHandler from 'infrastructure/apiHandlers/project';
import { projectsKey } from './constants';
import debounce from 'utils/helpers/debounce';

const useProjectsInfiniteQuery = (applicationId?: string) => {
  const { isLoggedChecking } = useAuthUser();
  const [searchTerm, setSearchTerm] = useState('');
  const enabled =
    (applicationId ? Boolean(applicationId) : true) && isLoggedChecking;

  const { data: projects, ...infiniteQueryProps } = useInfiniteQuery(
    [projectsKey, searchTerm],
    ({ pageParam = 1 }) =>
      projectApiHandler.getProjects({
        search: searchTerm,
        page: pageParam,
        first: pageAndLimitDefaultValue.first,
        applicationId
      }),
    {
      getNextPageParam: lastPage => {
        const {
          paginatorInfo: { hasMorePages, currentPage }
        } = lastPage;

        return hasMorePages ? currentPage + 1 : undefined;
      },
      enabled
    }
  );

  const onSearchTermHandler = debounce((searchTerm: string[]) => {
    setSearchTerm(searchTerm[0]);
  }, 500);

  return {
    data: useMemo(
      () => projects?.pages.flatMap(project => project.data) || [],
      [projects?.pages]
    ),
    onSearchTermHandler,
    ...infiniteQueryProps
  };
};

export default useProjectsInfiniteQuery;
